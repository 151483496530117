<template>
  <div style="position: relative">
    <!-- 头部按钮 -->
    <div style="padding: 20px">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        background-color="RGB(245,246,249)"
        text-color="RGB(122,142,240)"
        active-text-color="RGB(122,142,240)"
      >
        <el-menu-item index="1">
          <i class="el-icon-connection"></i>
          处理中心
        </el-menu-item>
      </el-menu>
      <el-input
        v-model="input"
        placeholder="请输入内容"
        style="
          position: absolute;
          width: 300px;
          top: 30px;
          left: 50%;
          transform: translateX(-50%);
        "
      >
        <el-button
          slot="append"
          style="background: RGB(33, 87, 250); color: white; border-radius: 0"
          >搜索</el-button
        >
      </el-input>
      <el-button type="danger" style="position: absolute; top: 30px; left: 80%">
        <i class="el-icon-plus"></i>
        新建线索
      </el-button>
      <el-button style="position: absolute; top: 30px; right: 6%"
        >重置</el-button
      >
      <el-button style="position: absolute; top: 30px; right: 1%">
        <i class="el-icon-more"></i>
      </el-button>
    </div>
    <!-- 内容区 -->
    <div>
      <el-card class="box-card" style="height: 100vh; position: relative">
        <div></div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: '1',
    };
  },
  methods: {},
};
</script>

<style scoped>
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
</style>
